import React, { useState } from 'react';
import './Header.css';
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';

import { Link } from '@react-navigation/native';

const Header = () => {

  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className='header'>
        <img className='logo' src={Logo} alt='logo' />

        {(menuOpened===false && mobile===true)? (
          <div style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px',
          }}
          onClick={()=> setMenuOpened(true)}
          >
            <img src={Bars} alt='burger-menu' style={{width: '1.5rem', height: '1.5rem'}}/>
          </div> ) : (
          <ul className='header-menu'>
            <li><a 
             onClick={()=>setMenuOpened(false)}
             href='#home'
             >Home</a></li>
            <li><a
              onClick={()=>setMenuOpened(false)}
              href='#programs'>Programs</a></li>
            <li><a onClick={()=>setMenuOpened(false)}
              href='#reasons'>Why us</a></li>
            <li><a onClick={()=>setMenuOpened(false)}
              href='#plans'>Plans</a></li>
            <li><a onClick={()=>setMenuOpened(false)}
              href='#testimonials'
            >Testimonials</a></li>
          </ul>
        )}
    </div>
  )
}

export default Header